import React, { useContext, useEffect } from "react"

import { OverlayContent, Head } from "@dom"

import { SiteContext } from "@context/siteContext"

export default function Leaderboard() {
  const { actions, transition } = useContext(SiteContext)
  useEffect(() => {
    if (!transition) actions.setTransition(true)
    setTimeout(() => {
      actions.setReady(true)
    }, 666)
  }, [])
  return (
    <>
      <Head />
      <OverlayContent mode={"index"} />
    </>
  )
}
